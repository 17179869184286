import $ from "jquery";
import validate from "validate.js";
import IBAN from "iban";

require("./slider");

//get params from url for english translations
var urlParams = new URLSearchParams(window.location.search);
const localeParam = urlParams && urlParams.get('lang')
let isEnglish = localeParam === "en" ? true : false

window.resetPasswordValidationConstraints = {
  "input-password": {
    presence: {
      message: isEnglish ? "Please enter a new password." : "Bitte gib ein neues Passwort ein."
    },
    length: {
      minimum: 6,
      message: isEnglish ? "Your password must be at least 6 characters long." : "Dein Passwort muss mindestens 6 Zeichen lang sein."
    }
  }
};
window.cummunityValidationConstraints = {
  nickname: {
    presence: {
      message: isEnglish ? "Please enter a nickname" : "Bitte gib einen Nicknamen ein."
    },
    length: {
      minimum: 2,
      maximum: 20,
      message:
      isEnglish ? "Your nickname may be between 2 and 20 characters long!" : "Dein Nickname muss mindestens 2 und maximal 20 Zeichen lang sein"
    }
  },
  greeting: {
    presence: {
      message: isEnglish ? "Please enter some text." : "Bitte gib einen Text ein."
    },
    length: {
      minimum: 6,
      maximum: 80,
      message: isEnglish ? "The text may not exceed the maximum of 80 characters." : "Der Text darf maximal 80 Zeichen lang sein"
    }
  }
};
window.resetPasswordEmailValidationConstraints = {
  "input-email": {
    presence: true,
    email: {
      message:
       isEnglish ? "Please enter the e-mail address which was used to create your account." : "Bitte gib die E-Mail-Adresse ein, mit der Du hier angemeldet bist."
    }
  }
};

window.signinValidationConstraints = {
  "input-email": {
    presence: true,
    email: {
      message: isEnglish ? "Please enter a valid e-mail address." : "Bitte gib eine gültige E-Mail-Adresse ein."
    }
  },
  "input-password": {
    presence: true
  }
};

window.raffleValidationConstraints = {
  firstname: {
    presence: {
      message: isEnglish ? "Please enter your first name." : "Bitte gib deinen Vornamen an."
    },
    length: {
      maximum: 100,
      message: isEnglish ? "Your first name may not exceed 100 characters." : "Dein Vorname kann nicht länger als 100 Zeichen sein."
    }
  },
  lastname: {
    presence: {
      message: isEnglish ? "Please enter your surname." : "Bitte gib deinen Nachnamen an."
    },
    length: {
      maximum: 100,
      message: isEnglish ? "Your surname may not exceed 100 characters." : "Dein Nachname kann nicht länger als 100 Zeichen sein."
    }
  },
  street: {
    presence: {
      message: isEnglish ? "Please enter your street address." : "Bitte gib deine Strasse ein."
    },
    length: {
      minimum: 2,
      maximum: 150,
      message:
      isEnglish ? "Your address may be between 2 and 50 characters long." : "Deine Adresse kann nicht kürzer als 2 und nicht länger als 50 Zeichen sein."
    }
  },
  zip: {
    presence: {
      message: isEnglish ? "Please enter your zip code." : "Bitte gib dein Postleitzahl ein."
    },
    format: {
      pattern: /\d{5}/,
      message: isEnglish ? "Please enter a valid German zip code (\"Postleitzahl\")." : "Bitte gib ein gültige deutsche Postleitzahl ein."
    }
  },
  city: {
    presence: {
      message: isEnglish ? "Please enter the name of your city." : "Bitte gib dein Stadt ein."
    },
    length: {
      maximum: 150,
      message: isEnglish ? "Your address may not exceed 50 characters." : "Deine Adresse kann nicht länger als 50 Zeichen sein."
    }
  },
  gender: {
    presence: {
      message: isEnglish ? "Please select your sex." : "Bitte wähle ein Geschlecht."
    }
  },
  education: {
    presence: {
      message: isEnglish ? "Please tell us about your educational background." : "Bitte sag etwas über deine schulische Laufbahn."
    }
  },
  family: {
    presence: {
      message:
      isEnglish ? "We need to know some more about your family situation." : "Wir müssen auch noch etwas über deine familiäre Situation wissen."
    }
  },
  jobtraining: {
    presence: {
      message:
      isEnglish ? "We also need to know something about your career." : "Wir müssen auch noch etwas über deine berufliche Ausbildung wissen."
    }
  },
  birthdate: {
    presence: {
      message: isEnglish ? "Please enter your date of birth." : "Bitte gib dein Geburtsdatum ein."
    },
    birthdate: true
  },
  children: {
    presence: {
      message: isEnglish ? "Please provide some information on your children." : "Bitte mach Angaben zu deinen Kindern"
    },
    length: {
      maximum: 30,
      message: isEnglish ? "You cannot have more than 30 children" : "Du kannst nicht mehr als 30 Kinder haben"
    }
  },
  currently_receiving: {
    presence: {
      message: isEnglish ? "Are you currently receiving social welfare (\"Arbeitslosengeld II\", colloquially known \"Hartz 4\"?)" : "Beziehst du gerade im Moment Hartz 4?"
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Are you currently receiving social welfare (\"Arbeitslosengeld II\", colloquially known \"Hartz 4\"?)" : "Beziehst du gerade im Moment Hartz 4?"
    }
  },
  toppingup: {
    presence: {
      message: isEnglish ? "Do you have any additional income apart from your welfare benefits." : "Verdienst du neben Hartz4 noch etwas dazu?"
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Do you have any additional income apart from your welfare benefits." : "Verdienst du neben Hartz4 noch etwas dazu?"
    }
  },
  freelancer: {
    presence: {
      message: isEnglish ? "Are you self-employed?" : "Bist du selbstständig?"
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Are you self-employed?" : "Bist du selbstständig?"
    }
  },
  already_victim: {
    presence: {
      message: isEnglish ? "Have you been sanctioned?" : "Wurdest du schon sanktioniert?"
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Have you been sanctioned?" : "Wurdest du schon sanktioniert?"
    }
  },
  userAgreement: {
    presence: {
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    }
  }
};
window.signupHartzplusValidationConstraints = {
  "input-email": {
    presence: true,
    email: {
      message: isEnglish ? "Please enter a valid e-mail address." : "Bitte gib eine gültige E-Mail-Adresse ein."
    }
  },
  "input-password": {
    presence: {
      message: isEnglish ? "Please enter a new password." : "Bitte gib ein neues Passwort ein."
    },
    length: {
      minimum: 6,
      message: isEnglish ? "Your password must be at least 6 characters long." : "Dein Passwort muss mindestens 6 Zeichen lang sein."
    }
  },
  firstname: {
    presence: {
      message: isEnglish ? "Please enter your first name." : "Bitte gib deinen Vornamen an."
    },
    length: {
      maximum: 100,
      message: isEnglish ? "Your first name may not exceed the maximum of 100 characters." : "Dein Vorname kann nicht länger als 100 Zeichen sein."
    }
  },
  lastname: {
    presence: {
      message: isEnglish ? "Please enter your surname." : "Bitte gib deinen Nachnamen an."
    },
    length: {
      maximum: 100,
      message: isEnglish ? "Your surname may not exceed the maximum of 100 characters." : "Dein Nachname kann nicht länger als 100 Zeichen sein."
    }
  },
  birthdate: {
    presence: {
      message: isEnglish ? "Please enter your date of birth." : "Bitte gib dein Geburtsdatum ein."
    },
    birthdate: true
  },
  street: {
    presence: {
      message: isEnglish ? "Please enter your street address." : "Bitte gib dein Strasse ein."
    },
    length: {
      minimum: 2,
      maximum: 150,
      message:
      isEnglish ? "Your address may be between 2 and 50 characters long." : "Deine Adresse kann nicht kürzer als 2 und nicht länger als 50 Zeichen sein."
    }
  },
  zip: {
    presence: {
      message: isEnglish ? "Please enter your zip code." : "Bitte gib dein Postleitzahl ein."
    },
    format: {
      pattern: /\d{5}/,
      message:  isEnglish ? "Please enter a valid German zip code (\"Postleitzahl\")." : "Bitte gib ein gültige deutsche Postleitzahl ein."
    }
  },
  city: {
    presence: {
      message: isEnglish ? "Please enter the name of your city." : "Bitte gib dein Stadt ein."
    },
    length: {
      maximum: 150,
      message: isEnglish ? "Your address may not exceed 50 characters." : "Deine Adresse kann nicht länger als 50 Zeichen sein."
    }
  },
  gender: {
    presence: {
      message: isEnglish ? "Please select your sex." : "Bitte wähle ein Geschlecht."
    }
  },
  education: {
    presence: {
      message: isEnglish ? "Please tell us about your educational background." : "Bitte sag etwas über deine schulische Laufbahn."
    }
  },
  children: {
    presence: {
      message: isEnglish ? "Please provide some information on your children." : "Bitte mach Angaben zu deinen Kindern"
    },
    length: {
      maximum: 30,
      message: isEnglish ? "You cannot have more than 30 children." : "Du kannst nicht mehr als 30 Kinder haben"
    }
  },
  education: {
    presence: {
      message: isEnglish ? "Please tell us about your educational background." : "Bitte sag etwas über deine schulische Laufbahn."
    }
  },
  jobtraining: {
    presence: {
      message:
        isEnglish ? "We also need to know something about your career." : "Wir müssen auch noch etwas über deine berufliche Ausbildung wissen."
    }
  },
  currently_receiving: {
    presence: {
      message: isEnglish ? "Are you currently receiving social welfare (\"Arbeitslosengeld II\", colloquially known \"Hartz 4\"?)" : "Beziehst du gerade im Moment Hartz 4?"
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Are you currently receiving social welfare (\"Arbeitslosengeld II\", colloquially known \"Hartz 4\"?)" : "Beziehst du gerade im Moment Hartz 4?"
    }
  },
  toppingup: {
    presence: {
      message: isEnglish ? "Do you have any additional income apart from your welfare benefits." : "Verdienst du neben Hartz4 noch etwas dazu?"
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Do you have any additional income apart from your welfare benefits." : "Verdienst du neben Hartz4 noch etwas dazu?"
    }
  },
  freelancer: {
    presence: {
      message: isEnglish ? "Are you self-employed?" : "Bist du selbstständig?"
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Are you self-employed?" : "Bist du selbstständig?"
    }
  },
  already_victim: {
    presence: {
      message: isEnglish ? "Have you been sanctioned?" : "Wurdest du schon sanktioniert?"
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Have you been sanctioned?" : "Wurdest du schon sanktioniert?"
    }
  },
  family: {
    presence: {
      message:
        isEnglish ? "We need to know some more about your family situation." : "Wir müssen auch noch etwas über deine familiäre Situation wissen."
    }
  },
  userAgreement: {
    presence: {
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    }
  }
};
window.signupValidationConstraints = {
  "input-email": {
    presence: true,
    email: {
      message: isEnglish ? "Please enter a valid e-mail address." : "Bitte gib eine gültige E-Mail-Adresse ein."
    }
  },
  "input-password": {
    presence: {
      message: isEnglish ? "Please enter a new password." : "Bitte gib ein neues Passwort ein."
    },
    length: {
      minimum: 6,
      message: isEnglish ? "Your password must be at least 6 characters long." : "Dein Passwort muss mindestens 6 Zeichen lang sein."
    }
  },
  passwordConfirmation: {
    presence: {
      message:  isEnglish ? "Please repeat your password." : "Bitte gib dein Passwort noch ein zweites Mal ein."
    },
    equality: {
      attribute: "input-password",
      message:  isEnglish ? "The passwords don't match." : "Du musst zweimal dasselbe Passwort eingeben."
    }
  },
  userAgreement: {
    presence: {
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    }
  }
};

window.campaignSignupValidationConstraints = {
  "input-email": {
    presence: true,
    email: {
      message: isEnglish ? "Please enter a valid e-mail address." : "Bitte gib eine gültige E-Mail-Adresse ein."
    }
  },
  firstname: {
    presence: {
      message: isEnglish ? "Please enter your first name." : "Bitte gib deinen Vornamen an."
    },
    length: {
      maximum: 100,
      message: isEnglish ? "Your first name may not exceed the maximum of 100 characters." : "Dein Vorname kann nicht länger als 100 Zeichen sein."
    }
  },
  lastname: {
    presence: {
      message: isEnglish ? "Please enter your surname." : "Bitte gib deinen Nachnamen an."
    },
    length: {
      maximum: 100,
      message: isEnglish ? "Your surname may not exceed the maximum of 100 characters." : "Dein Nachname kann nicht länger als 100 Zeichen sein."
    }
  },
  birthdate: {
    presence: {
      message: isEnglish ? "Please enter your date of birth." : "Bitte gib dein Geburtsdatum ein."
    },
    birthdate: true
  },
  userAgreement: {
    presence: {
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    }
  },
  termsAndConditions: {
    presence: {
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte bestätige, dass du die Teilnahmebedingungen gelesen hast und deine Angaben deine Angaben richtig sind."
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte bestätige, dass du die Teilnahmebedingungen gelesen hast und deine Angaben deine Angaben richtig sind."
    }
  },
  paymentReceiver: {
    presence: {
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte bestätige, dass du Leistungen beziehst und das nachweisen kannst."
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte bestätige, dass du Leistungen beziehst und das nachweisen kannst."
    }
  }
};
window.authCodeValidationContstraints = {
  "input-authCode": {
    presence: true,
    length: {
      minimum: 5,
      maximum: 5
    }
  }
}
window.winnerUploadValidationConstraints = {
  "document-input": {
    presence: {
      message: "Lade ein Foto von Seite 1 deines Leistungsbescheids + EC-Karte hoch."
    },
  },
  iban: {
    presence: {
      message: isEnglish ? "Please enter your IBAN." : "Bitte gib deine IBAN ein."
    },
    iban: true
  },
  userAgreement: {
    presence: {
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    }
  }
}
window.schoolDocUploadValidationConstraints = {
  "document-input": {
    presence: {
      message: "Lade ein Foto vom Berechnungsbogen deines Leistungsnachweises hoch."
    }
  }
}
window.passwordUpdateValidationConstraints = {
  "input-existing-password": {
    presence: {
      message: isEnglish ? "Please enter your current password." : "Bitte gib dein aktuelles Passwort ein."
    },
    length: {
      minimum: 6,
      message: isEnglish ? "Your current password must be at least 6 characters long." : "Dein aktuelles Passwort muss mindestens 6 Zeichen lang sein."
    }
  },
  "input-new-password": {
    presence: {
      message: isEnglish ? "Please enter your new password." : "Bitte gib dein neues Passwort ein."
    },
    length: {
      minimum: 6,
      message: isEnglish ? "Your new password must be at least 6 characters long." : "Dein neues Passwort muss mindestens 6 Zeichen lang sein."
    }
  },
  "input-new-password-confirmation": {
    presence: {
      message: isEnglish ? "Please repeat your new password." : "Bitte gib dein neues Passwort noch ein zweites Mal ein."
    },
    equality: {
      attribute: "input-new-password",
      message: isEnglish ? "The passwords don't match." : "Du musst zweimal dasselbe Passwort eingeben."
    }
  }
};

window.emailUpdateValidationConstraints = {
  "input-existing-password": {
    presence: {
      message: isEnglish ? "Please enter your current password." : "Bitte gib dein aktuelles Passwort ein."
    },
    length: {
      minimum: 6,
      message: isEnglish ? "Your current password must be at least 6 characters long." : "Dein aktuelles Passwort muss mindestens 6 Zeichen lang sein."
    }
  },
  "input-email": {
    presence: true,
    email: {
      message: isEnglish ? "Please enter a valid e-mail address." : "Bitte gib eine gültige E-Mail-Adresse ein."
    }
  }
};
window.accountDeleteValidationConstraints = {
  "input-existing-password": {
    presence: {
      message: isEnglish ? "Please enter your current password." : "Bitte gib dein aktuelles Passwort ein."
    },
    length: {
      minimum: 6,
      message: isEnglish ? "Your current password must be at least 6 characters long." : "Dein aktuelles Passwort muss mindestens 6 Zeichen lang sein."
    }
  }
};

window.accountValidationConstraints = {
  "input-email": {
    presence: {
      message: isEnglish ? "Please enter a valid e-mail address." : "Bitte gib eine gültige E-Mail-Adresse ein."
    },
    email: {
      message: isEnglish ? "Please enter a valid e-mail address." : "Bitte gib eine gültige E-Mail-Adresse ein."
    }
  },
  "input-password": {
    presence: {
      message: isEnglish ? "Please enter a new password." : "Bitte gib ein neues Passwort ein."
    },
    length: {
      minimum: 6,
      message: isEnglish ? "Your password must be at least 6 characters long." : "Dein Passwort muss mindestens 6 Zeichen lang sein."
    }
  },

  passwordConfirmation: {
    presence: {
      message: isEnglish ? "Please repeat your password." : "Bitte gib dein Passwort noch ein zweites Mal ein."
    },
    equality: {
      attribute: "input-password",
      message: isEnglish ? "The passwords don't match." : "Du musst zweimal dasselbe Passwort eingeben."
    }
  }
};
window.accountAggreementValidationConstraints = {
  userAgreement: {
    presence: {
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Please agree to our privacy policy." : "Bitte kreuze an, dass Du den Datenschutzbestimmungen zustimmst."
    }
  }
};
window.signupDebitDonationValidationConstraints = {
  accountOwner: {
    presence: {
      message:
        "Dein voller Name muss mindestens zwei und maximal 100 Zeichen lang sein."
    },
    length: {
      minimum: 2,
      maximum: 100,
      message:
        "Dein voller Name kann nicht kürzer als 2 und nicht länger als 100 Zeichen sein."
    },
    format: {
      pattern: /^(?=.{1,100}$)[a-zA-ZÀ-ÿ'\-_,.][^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]+(?:[\s][a-zA-ZÀ-ÿ'\-_,.]{1,100}){1,}$/,
      message: "Bitte Vor- und Nachname angeben! Namen dürfen nicht kürzer als 1 und nicht länger als 100 Zeichen sein."
    }
  },

  streetAndNumber: {
    presence: {
      message:
        isEnglish ? "Your address must be between 2 and 50 characters long." : "Deine Adresse kann nicht kürzer als 2 und nicht länger als 50 Zeichen sein."
    },
    length: {
      maximum: 150,
      minimum: 3,
      message:
        isEnglish ? "Your address must be between 2 and 50 characters long." : "Deine Adresse kann nicht kürzer als 2 und nicht länger als 50 Zeichen sein."
    }
  },
  zipCode: {
    presence: {
      message: isEnglish ? "Please enter a valid German zip code (\"Postleitzahl\")." : "Bitte gib ein gültige deutsche Postleitzahl ein."
    },
    format: {
      pattern: /\d{5}/,
      message: isEnglish ? "Please enter a valid German zip code (\"Postleitzahl\")." : "Bitte gib ein gültige deutsche Postleitzahl ein."
    }
  },
  city: {
    presence: {
      message: isEnglish ? "Your city name may not exceed the maximum of 50 characters." : "Deine Stadt kann nicht länger als 50 Zeichen sein."
    },
    length: {
      maximum: 50,
      message: isEnglish ? "Your city name may not exceed the maximum of 50 characters." : "Deine Stadt kann nicht länger als 50 Zeichen sein."
    }
  },
  iban: {
    presence: {
      message: isEnglish ? "Please enter your IBAN." : "Bitte gib deine IBAN ein."
    },
    iban: true
  },
  bic: {
    presence: {
      message: isEnglish ? "The BIC needs to be added." : "Die BIC muss angegeben werden."
    },
    length: {
      minimum: 2,
      maximum: 30,
      message:
        isEnglish ? "The BIC must be between 2 and 30 characters long." : "Die BIC kann nicht kürzer als 2 und nicht länger als 30 Zeichen sein."
    }
  },
  amountOrganisation: {
    presence: {
      message: isEnglish ? "You must enter the amount you want to donate to our non-profit-organization." : "Die Vereinsarbeitsspende muss angegeben werden."
    },
    length: {
      minimum: 1,
      message:
        isEnglish ? "The minimum donation to the organization is 1 Euro." : "Die Vereinsarbeitsspende muss mindesten einen Euro betragen."
    }
  },
  amountSolidartopf: {
    presence: {
      message: isEnglish ? "You must enter the amount you want to donate to the solidarity pot." : "Die Solidartopfspende muss angegeben werden."
    }
  },
  debitPermission: {
    presence: {
      message: isEnglish ? "Please make sure to agree the direct debit mandate." : "Bitte kreuze an, dass Du der Einzugsermächtigung zustimmst."
    },
    inclusion: {
      within: [true],
      message: isEnglish ? "Please make sure to agree the direct debit mandate." : "Bitte kreuze an, dass Du der Einzugsermächtigung zustimmst."
    }
  }
};
window.debitDonationValidationConstraints = {
  accountOwner: {
    presence: {
      message: "Dein voller Name kann nicht länger als 100 Zeichen sein."
    },
    length: {
      maximum: 100,
      message: "Dein voller Name kann nicht länger als 100 Zeichen sein."
    },
    format: {
      pattern: /^(?=.{1,100}$)[a-zA-ZÀ-ÿ'\-_,.][^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]+(?:[\s][a-zA-ZÀ-ÿ'\-_,.]{1,100})$/,
      message: "Bitte Vor- und Nachname angeben! Namen dürfen nicht kürzer als 1 und nicht länger als 100 Zeichen sein."
    }
  },
  streetAndNumber: {
    presence: {
      message:
        isEnglish ? "Your address must be between 2 and 50 characters long." : "Deine Adresse kann nicht kürzer als 2 und nicht länger als 50 Zeichen sein."
    },
    length: {
      maximum: 150,
      message:
        isEnglish ? "Your address must be between 2 and 50 characters long." : "Deine Adresse kann nicht kürzer als 2 und nicht länger als 50 Zeichen sein."
    }
  },
  zipCode: {
    presence: {
      message: isEnglish ? "Please enter a valid German zip code (\"Postleitzahl\")." : "Bitte gib ein gültige deutsche Postleitzahl ein."
    },
    format: {
      pattern: /\d{5}/,
      message: isEnglish ? "Please enter a valid German zip code (\"Postleitzahl\")." : "Bitte gib ein gültige deutsche Postleitzahl ein."
    }
  },
  amountOrganisation: {
    presence: {
      message: isEnglish ? "You must enter the amount you want to donate to our non-profit-organization." : "Die Vereinsarbeitsspende muss angegeben werden."
    },
    length: {
      minimum: 1,
      message:
        isEnglish ? "The minimum donation to the organization is 1 Euro." : "Die Vereinsarbeitsspende muss mindesten einen Euro betragen."
    }
  },
  amountSolidartopf: {
    presence: {
      message: isEnglish ? "You must enter the amount you want to donate to the solidarity pot." : "Die Solidartopfspende muss angegeben werden."
    }
  },
  city: {
    presence: {
      message: isEnglish ? "Your city name must not exceed the maximum length of 50." : "Deine Stadt kann nicht länger als 50 Zeichen sein."
    },
    length: {
      maximum: 50,
      message: isEnglish ? "Your city name must not exceed the maximum length of 50." : "Deine Stadt kann nicht länger als 50 Zeichen sein."
    }
  },
  iban: {
    presence: {
      message: isEnglish ? "Please enter your IBAN." : "Bitte gib deine IBAN ein."
    },
    iban: true
  },
  bic: {
    presence: {
      message: isEnglish ? "The BIC needs to be added." : "Die BIC muss angegeben werden."
    },
    length: {
      minimum: 2,
      maximum: 30,
      message:
        isEnglish ? "The BIC must be between 2 and 30 characters long." : "Die BIC kann nicht kürzer als 2 und nicht länger als 30 Zeichen sein."
    }
  },
  debitPermission: {
    presence: {
      message: "Bitte kreuze an, dass Du der Einzugsermächtigung zustimmst."
    },
    inclusion: {
      within: [true],
      message: "Bitte kreuze an, dass Du der Einzugsermächtigung zustimmst."
    }
  }
};
var debounce = function(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this;
    var args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
// Returns a JSON object for all inputs in $form that aren't submit
var getJSONFromForm = function($form) {
  var inputValues = {};
  $form
    .find('input:not([type="submit"]), select, textarea')
    .each(function(index, input) {
      if ($(input).attr("type") === "checkbox") {
        inputValues[$(input).attr("id")] = $(input).prop("checked");
      } else if ($(input).attr("type") === "radio") {
        let oneRadioChecked = false;
        $(`input[name="${$(input).attr("name")}"]`).each(function(item, m) {
          oneRadioChecked = m.checked ? true : oneRadioChecked;
        });
        inputValues[$(input).attr("name")] = oneRadioChecked;
      } else {
        inputValues[$(input).attr("id")] = $(input).val();
      }
    });
  return inputValues;
};


// Display blocking error under form and show field hints
// Also returns true or false so we can use `validateForm()` on submit
var applyValidationResultsToForm = function(
  validationResults,
  $form,
  isFirstRun
) {
  $form.find(".invalid").removeClass("invalid");
  if (validationResults && validationResults.length > 0) {
    validationResults.forEach(function(result) {
      var $targetElement = $("#" + result.attribute);
      // Handle inputs inside a partial/component, where the entire component
      // should receive the `.invalid` class.
      if ($targetElement.closest(".component").length > 0) {
        $targetElement = $targetElement.closest(".component");
      }
      $targetElement.addClass("invalid");
    });
    $form.find("#valid").addClass("hidden");
    $form.find("#invalid").removeClass("hidden");
    if (!isFirstRun) {
      $form
        .find("#invalid")
        .removeClass("alert-light")
        .addClass("alert-danger");
    }
    $("#invalid").html(validationResults[0].error);
    return false;
  }
  $form.find("#valid").removeClass("hidden");
  $form.find("#invalid").addClass("hidden");
  return true;
};

var validateForm = function($form, isFirstRun) {
  var constraints = $form.attr("data-validation-constraints");
  var inputValues = getJSONFromForm($form);
  let validationResults = [];
  constraints.split(" ").map(c => {
    let validationResult = validate(inputValues, window[c], {
      format: "detailed"
    });

    validationResult && validationResults.push(validationResult);
  });
  validationResults = [].concat.apply([], validationResults);

  return applyValidationResultsToForm(validationResults, $form, isFirstRun);
};

$(function() {
  window.hartzbreaker = {
    options: {
      lastWindowWidth: $(window).width()
    }
  };

  // Set up the validator
  validate.validators.presence.options = {
    message: isEnglish ? "Please fill out everything so we may continue." : "Bitte fülle alles aus, damit wir weitermachen können."
  };
  // Without `fullMessages: false`, the field ID is prefixed to the error message, which is super useless.
  // https://github.com/ansman/validate.js/issues/117
  validate.options = {
    fullMessages: false
  };
  // Add IBAN validation constraint to validator
  validate.validators.iban = function(value, options, key, attributes) {
    if (!IBAN.isValid(value)) {
      return options.message || isEnglish ? "Please enter a valid IBAN number." : "Bitte gib eine gültige IBAN ein.";
    }
    return null;
  };

  validate.validators.birthdate = function(value, options, key, attributes) {
    var today = new Date();
    var inputdate = new Date(value);

    if (
      inputdate.setFullYear(inputdate.getFullYear()) >=
      today.setFullYear(today.getFullYear() - 16)
    ) {
      return (
        options.message ||
          isEnglish ? "The minimum age for the raffle is 16." : "Du musst älter als 16 Jahre sein um an der Verlosung teilzunehmen."
      );
    }
    return null;
  };

  // Define the debounced version of the validation function
  var debouncedFormValidation = debounce(validateForm, 250);

  // Validate any form with constraints on change
  $(
    "[data-validation-constraints] input, [data-validation-constraints] select, [data-validation-constraints] textarea"
  ).on("change keyup blur", function(event) {
    var $form = $(event.currentTarget).closest("form");
    debouncedFormValidation($form);
  });

  // Validate any form with constraints once on init
  if ($("[data-validation-constraints]").length > 0) {
    validateForm($("[data-validation-constraints]"), true);
  }

  // Block form submission if invalid
  $("#debitDonation").on("submit", function(event) {
    var $form = $(event.currentTarget).closest("form");
    // If the user somehow manages to click submit on an invalid form,
    // this should stop any actual submission
    if (!validateForm($form)) {
      event.preventDefault();
      return;
    }
  });

  // Helps us highlight invalid fields
  $('input:not([type="submit"]), select, textarea').on("blur", function(event) {
    $(event.currentTarget).addClass("has-had-focus");
  });
  var form = document.querySelector("form");
});