const sun = new Image();
const moon = new Image();
const earth = new Image();
const element = document.getElementById("headercanvas");
function init() {
  sun.src = "/images/header.png";
  moon.src = "/images/user-placeholder.jpg";
  earth.src = "/images/team/SF_TEAM_Tommy.jpg";
  window.requestAnimationFrame(draw);
}

function draw() {
  const ctx = element.getContext("2d");

  ctx.globalCompositeOperation = "destination-over";
  ctx.clearRect(0, 0, 300, 300); // clear canvas

  ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
  ctx.strokeStyle = "rgba(0, 153, 255, 0.4)";
  ctx.save();
  ctx.translate(150, 150);

  // Earth
  const time = new Date();
  ctx.rotate(
    ((2 * Math.PI) / 60) * time.getSeconds() +
      ((2 * Math.PI) / 60000) * time.getMilliseconds()
  );
  ctx.translate(125, 0);

  // ctx.scale(0.05, 0.05);
  // ctx.restore();
  // ctx.fillRect(0, -12, 40, 24); // Shadow
  ctx.drawImage(earth, -25, -25, 50, 50);

  // Moon
  ctx.save();
  ctx.rotate(
    ((2 * Math.PI) / 6) * time.getSeconds() +
      ((2 * Math.PI) / 6000) * time.getMilliseconds()
  );
  ctx.translate(0, 28.5);
  ctx.globalCompositeOperation = "destination-over";
  ctx.drawImage(moon,  -25, -25, 50, 50);
  ctx.restore();

  ctx.restore();

  ctx.beginPath();
  ctx.arc(150, 150, 125, 0, Math.PI * 2, false); // Earth orbit
  ctx.stroke();

  ctx.drawImage(sun, 0, 0, 300, 300);

  window.requestAnimationFrame(draw);
}
if (element) {
  init();
}
