/* global, validate */
import $ from "jquery";
import "../../css/index.sass";
import "./count-numbers";
import "./canvas";
import "./youtubeslider";
import "./validation";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/carousel";

if (document.getElementById("carouselmorebutton")) {
  document
    .getElementById("carouselmorebutton")
    .addEventListener("click", () => {
      $("#carouselExampleIndicators").carousel("next");
      // document.getElementById('carouselmorebutton').classList.add('fading')
    });
}
if (
  document.getElementById("already_subscribed") !== null &&
  localStorage.getItem("hartzbreaker_newsletter")
) {
  document
    .getElementById("already_subscribed")
    .querySelector("span").innerText = localStorage.getItem(
    "hartzbreaker_newsletter"
  );
  document.getElementById("already_subscribed").classList.remove("d-none");
}
$("#nl_form").on("submit", function (e) {
  e.preventDefault();
  if (this.elements["age"].value) {
    return;
  }
  var payload = {
    email: this.elements["EMAIL"].value,
    age: this.elements["age"].value,
  };
  document.getElementById("nl_btn_text").classList.add("d-none");
  document.getElementById("nl_btn_icon").classList.remove("d-none");
  document.getElementById("nl_btn_icon").classList.add("d-block");
  document
    .getElementById("nl_form")
    .querySelector(".inputs")
    .classList.add("d-none");

  document.querySelector("#newsletter-button").disabled = true;
  fetch("/newsletter", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((response) => {
      document.getElementById("nl_btn_text").classList.remove("d-none");
      document.getElementById("nl_btn_icon").classList.add("d-none");
      document.getElementById("nl_btn_icon").classList.remove("d-block");
      if (response.unique_email_id !== undefined) {
        var text =
          "Juhu - du hast dich für unseren Newsletter registriert! Jetzt musst du nur noch den Bestätigungslink klicken, den wir dir an deine E-Mail-Adresse gesendet haben";
        localStorage.setItem("hartzbreaker_newsletter", payload.email);
        document
          .getElementById("already_subscribed")
          .querySelector("span").innerText = payload.email;
      } else if (response.title === "Member Exists") {
        document
          .getElementById("nl_form")
          .querySelector(".inputs")
          .classList.remove("d-none");
        document.querySelector("#newsletter-button").disabled = false;

        var text = "Diese E-Mail-Adresse steht bereits auf unserer Liste.";
      } else {
        document
          .getElementById("nl_form")
          .querySelector(".inputs")
          .classList.remove("d-none");
        document.querySelector("#newsletter-button").disabled = false;

        var text = "Oh - es scheint etwas schief gegangen zu sein.";
      }
      document.getElementById("response").classList.remove("d-none");
      document.getElementById("response").innerText = text;
    });
});
$(".collapse.faq").on("hide.bs.collapse", function () {
  this.parentElement.querySelector(".faq-closed").classList.add("hidden");
  this.parentElement.querySelector(".faq-open").classList.remove("hidden");
});
$(".collapse.faq").on("show.bs.collapse", function () {
  this.parentElement.querySelector(".faq-closed").classList.remove("hidden");
  this.parentElement.querySelector(".faq-open").classList.add("hidden");
});

$(".nav-item.dropdown").on("click", function () {
  $(this).find(".dropdown-menu").toggle("display");
});

// for (var value of document.querySelectorAll("header a, footer a")) {
//   value.addEventListener("click", item => {
//     document.querySelector("body").classList.add("fade")
//     let svg = document.getElementById("svg");
//     svg.setAttribute("style", `left: ${event.clientX}px; top: ${event.clientY}px;`);
//
//     var delay = 50
//     let circle = svg.querySelector("circle")
//     for (let i = 0; i < 100; i++) {
//       setTimeout(function() {
//         circle.setAttribute("r", i);
//       }, delay += 3)
//     }
//   })
// }

const debitFormButton = document.getElementById("scrollToDebitDonation");
if (debitFormButton) {
  debitFormButton.addEventListener("click", (event) => {
    event.preventDefault();
    document.getElementById("scrollToDebitDonation").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  });
}
var scrollbuttons = document.getElementsByClassName("scrollbutton");
for (var i = 0, len = scrollbuttons.length; i < len; i++) {
  scrollbuttons[i].addEventListener("click", function (event) {
    event.preventDefault();
    document.querySelector(event.target.attributes.href.value).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  });
}
var numbers = [...document.querySelectorAll("g[id$='x25_']")];
function switchElements(elements) {
  elements.forEach((n) => (n.style.display = "none"));
  elements.push(elements.shift());
  elements[0].style.display = "block";
  if (Date.now() % 2 === 0) {
    //console.log("Every second time");
  }
  setTimeout(function () {
    switchElements(elements);
  }, 1000);
}
if (numbers.length > 0) {
  switchElements(numbers);
}

var numbers2 = [...document.querySelectorAll("g[id$='x2F_250']")];
function switchElements2(elements) {
  elements.forEach((n) => (n.style.display = "none"));
  elements.push(elements.shift());
  elements[0].style.display = "block";

  setTimeout(function () {
    switchElements2(elements);
  }, 500);
}
if (numbers2.length > 0) {
  switchElements2(numbers2);
}

var elems = document.getElementsByClassName("confirmation");
var confirmIt = function (e) {
  if (!confirm("Bist du dir sicher? Wir werden dich sehr vermissen!"))
    e.preventDefault();
};
for (var i = 0, l = elems.length; i < l; i++) {
  elems[i].addEventListener("click", confirmIt, false);
}

// clickhandler for language switching

const languageSwitcher = document.getElementsByClassName("language-switcher");
for (let i = 0, len = languageSwitcher.length; i < len; i++) {
  languageSwitcher[i].addEventListener("click", function (event) {
    event.preventDefault();
    console.log(event.target.dataset.query)
    fetch(`${event.target.pathname}`, {
      method: "GET"
    }).then(window.location.href = event.target.dataset.query)
  });
}

// add locale to external anchors (e.g. link to platform)

const anchorElement = document.getElementsByClassName("add-locale");
for(let i = 0; i < anchorElement.length; i++) {
  const oldHref = anchorElement[i].href
  const newHref = oldHref + "#en"
  anchorElement[i].href = newHref
}

// handle file input element
const input = document.getElementById("document-input");
const inputLabel = document.getElementById("document-label")
const preview = document.getElementById("document-preview")
const alert = document.querySelector(".file-alert")
const submit = document.getElementById("winner-submit")
if (input) {
  input.addEventListener("change", updateImageDisplay);
}

function updateImageDisplay() {
  while (preview.firstChild) {
    preview.removeChild(preview.firstChild);
  }
  while (alert.firstChild) {
    alert.removeChild(alert.firstChild)
  }
  const curFiles = input.files;
  if (curFiles.length === 0) {
    inputLabel.textContent = "Datei auswählen";
  } else {
    const div = document.createElement("div");
    const alertDiv = document.createElement("div")
    alertDiv.classList.add("alert", "alert-danger")
    preview.appendChild(div);
    alert.appendChild(alertDiv)

    for (const file of curFiles) {
      const container = document.createElement("div");
      const alertContainer = document.createElement("div");
      const text1 = document.createElement("span");
      const text2 = document.createElement("span");
      if (validFileType(file)) {
        if (validFileSize(file)) {
          text1.textContent = `${file.name}`;
          text2.textContent = `Dateigröße: ${returnFileSize(
            file.size,
          )}`;
          inputLabel.textContent = file.name
          const image = document.createElement("img");
          image.src = URL.createObjectURL(file);

          const iframe = document.createElement("iframe")
          iframe.src = `${URL.createObjectURL(file)}`
          iframe.width = "100%"
          iframe.type = "application/pdf"

          image.classList.add("mt-3")
          iframe.classList.add("mt-3")

          container.appendChild(text2);
          if (file.type === "application/pdf") {
            container.appendChild(iframe);
          } else {
            container.appendChild(image);
          }
          submit.disabled = false
          alertDiv.classList.remove("alert", "alert-danger")
        } else {
          text1.textContent = `Die Datei ist zu groß! (${returnFileSize(file.size,)})`
          inputLabel.textContent = file.name
          submit.disabled = true
          alertContainer.appendChild(text1);
        }
      } else {
        text1.textContent = `Ungültiges Dateiformat.`;
        inputLabel.textContent = file.name
        submit.disabled = true
        alertContainer.appendChild(text1);
      }
      alertDiv.appendChild(alertContainer);
      div.appendChild(container);
    }
  }
}

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
  "image/x-icon",
  "application/pdf"
];

function validFileType(file) {
  return fileTypes.includes(file.type);
}

function validFileSize(file) {
  const maxSize = 5242880
  if (file.size <= maxSize) {
    return file
  }
}

function returnFileSize(number) {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
}

// handle winner form submit
$("#winner-form").on("submit", async function (e) {
  e.preventDefault()
  const file = input.files ? input.files[0] : {}
  const IBAN = this.elements.iban.value.toUpperCase()
  const path = e.target.attributes['action'].value
  const regex = /\/winner\/([a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12})/
  const match = regex.exec(path)
  const baseUrl = `${process.env.COUCH_URL}/${process.env.CAMPAIGNS_DATABASE_NAME}/`


  if (match) {
    if (validFileSize(file)) {
      const id = match[1]
      const formData = new FormData();
      formData.append('file', file);
      formData.append('IBAN', IBAN);
      /* uploadFile(id, file) */
      await fetch(path, {
        method: "POST",
        body: formData
      }).then((res) => {
        if (res.ok) {
          window.location.href = res.url
        }
        return
      })
        .catch("error")
    }
  }
})

// handle school document form submit
$("#school-document-form").on("submit", async function (e) {
  e.preventDefault()
  const file = input.files ? input.files[0] : {}
  const path = e.target.attributes['action'].value
  const regex = /\/winner\/schulbescheid\/([a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12})/
  const match = regex.exec(path)

  if (match) {
    if (validFileSize(file)) {
      const formData = new FormData();
      formData.append('file', file);
      await fetch(path, {
        method: "POST",
        body: formData
      }).then((res) => {
        if (res.ok) {
          window.location.href = res.url
        }
        return
      })
        .catch("error")
    }
  }
})

const popup = document.getElementById("popup");
const btnSignup = document.getElementById("btn-signup");
const popupClose = popup.querySelector(".popup-close")
const buttonIcon = popup.querySelector("#button-icon")

$(popupClose).click(function () {
  $(popup).toggleClass("open")
  $(buttonIcon).toggleClass("arrow_carrot-down arrow_carrot-up")
});

$(btnSignup).click(function () {
  $(popup).removeClass("open")
  $(buttonIcon).removeClass("arrow_carrot-down")
  $(buttonIcon).addClass("arrow_carrot-up")
});

/* $(".popup-close").on("click", function () {
  this.parentElement.querySelector(".closed").classList.remove("closed");
  this.parentElement.querySelector(".open").classList.add("open");
}); */

/* function togglePopup() {
  console.log("clicked")
  if (closed) {
    console.log("not open")
    popup.classList.remove("closed")
    popup.classList.add("open")
  }
  if (open) {
    console.log("open")
    popup.classList.remove("open")
    popup.classList.add("closed")
  }
}

if (popup) {
  popupClose.addEventListener("click", () => {
    togglePopup()
  });
} */


//const throttle = (func, limit) => {
//let lastFunc
//let lastRan
//return function() {
//const context = this
//const args = arguments
//if (!lastRan) {
//func.apply(context, args)
//lastRan = Date.now()
//} else {
//clearTimeout(lastFunc)
//lastFunc = setTimeout(function() {
//if ((Date.now() - lastRan) >= limit) {
//func.apply(context, args)
//lastRan = Date.now()
//}
//}, limit - (Date.now() - lastRan))
//}
//}
//}

//window.onscroll = throttle(function() {myFunction()}, 10);

//var floatings = [...document.querySelectorAll(".floatings")]
//function myFunction() {
//floatings.forEach((item) => {
//if(elementInViewport2(item) && window.innerWidth > 800){
//let transform = ( window.pageYOffset - item.parentElement.offsetTop + ( item.height / 2 ) ) / 12
//item.style.transform = `translateY(${transform}px)`
//}
//})
//}
//function elementInViewport2(el) {
//var top = el.offsetTop;
//var left = el.offsetLeft;
//var width = el.offsetWidth;
//var height = el.offsetHeight;

//while(el.offsetParent) {
//el = el.offsetParent;
//top += el.offsetTop;
//left += el.offsetLeft;
//}

//return (
//top < (window.pageYOffset + window.innerHeight) &&
//left < (window.pageXOffset + window.innerWidth) &&
//(top + height) > window.pageYOffset &&
//(left + width) > window.pageXOffset
//);
//}