const org = document.getElementById("amountOrganisation");
const soli = document.getElementById("amountSolidartopf");
const total = document.getElementById("amountTotal");
const debitTotal = document.querySelector(".amountTotal");

if (org && soli && total) {
  soli &&
    soli.addEventListener("change", () => {
      if (soli.value !== "") {
        total.innerHTML =
          total.innerHTML !== ""
            ? parseInt(org.value) + parseInt(soli.value)
            : total.innerHTML;
          if (org.value == 0 && soli.value == 0) {
            org.value = "1";
            total.innerHTML = "1";
          }
        debitTotal.innerHTML = parseInt(total.innerHTML);
      }
    });
  org &&
    org.addEventListener("change", () => {
      if (org.value !== "") {
        total.innerHTML =
          total.innerHTML !== ""
            ? parseInt(org.value) + parseInt(soli.value)
            : total.innerHTML;
          if (soli.value == 0 && org.value == 0) {
            soli.value = "1"
            total.innerHTML = "1";
          }
        debitTotal.innerHTML = parseInt(total.innerHTML);
      }
    });
  total &&
    total.addEventListener("change", () => {
      if (total.innerHTML !== "") {
        if (org.value > 0) {
          org.value =
            org.value !== ""
              ? parseInt(total.innerHTML) - parseInt(soli.value)
              : org.value;
        } else {
          soli.value =
            soli.value !== ""
              ? parseInt(total.innerHTML) - parseInt(org.value)
              : soli.value;
        }
        debitTotal.innerHTML = parseInt(total.innerHTML);
      }
    });
}
